.overlay-pwa-installation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 25px;
}

.box-pwa-installation {
    background: #FFFFFF;
    padding: 10px 10px 20px;
    width: 100%;
    max-width: 312px;
    height: auto;
    border-radius: 16px;
    box-shadow: 0px 0px 6px #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.close-icon-outer{
    position: absolute;
    height: 30px;
    width: 30px;
    top: 10px;
    right: 10px;

}

.close-icon-outer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pwa-header-outer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
}

.pwa-header-outer p{
    font-size: 18px;
    font-family: 'SF Pro Display, Semibold';
    color: #434343;
}
.pwa-installation-top-image{
    width: 100%;
    height: 77px;
    max-width: 53px;
    margin-bottom: 20px;
}

.pwa-installation-top-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.text-outer-pwa-installation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 25px;
}

.button-group-pwa-installation {
    width: 100%;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.confirm-button-pwa-installation-end {
    width: 105px;
    height: 40px;
    background: linear-gradient(90deg, #EA55FF, #A659FD);
    border-radius: 25px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.confirm-button-pwa-installation-start {
    width: 105px;
    height: 40px;
    border :2px solid #E755FF ;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.text-pwa-installation {
    font-family: 'SF Pro Display, Regular';
    font-size: 17px;
    text-align: center;
    margin-bottom: 15px !important;
    color: #434343;
}
.text-pwa-installation-bottomEnd {
    font-family: 'SF Pro Display, Regular';
    font-size: 17px;
    text-align: center;
    color: #434343;
}

.confirm-text-pwa-installation-end {
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'SF Pro Display, Medium';
}
.confirm-text-pwa-installation-start {
    color: #E655FF;
    font-size: 20px;
    font-family: 'SF Pro Display, Medium';
}