.rotate-screen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #5A5A5A;
    text-align: center;
    position: fixed;
    z-index: 9999;
  }
  
  .rotate-screen-content {
    transform: rotate(0deg);
    color: white;
  }
  .rotate-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
  }
  .rotate-image {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .rotate-text h1 {
    font-size: 28px;
    font-family: 'SF Pro Display , Bold';

  }

  .rotate-text p {
    font-size: 20px;
    font-family: 'SF Pro Display, Regular';
  }
  