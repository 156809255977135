@media (min-width: 768px) {
    .fake-call-wrapper {
        border: 2px solid var(--primary-color); 
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        max-width: 430px; 
        margin:0 auto
    }
}

.fake-call-wrapper{
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fake-videoCall-page-inner{
    width: 100%;
    height: 100%;
    background-color: #00000040;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    z-index: 99;
    filter: blur(10px);
    -webkit-filter: blur(10px);
  }
   
  .host-lottie-animation {
    position: absolute;
    width: 100%;
    height: 425px;
    top: 45px;
    z-index: 99;
  }
   
   
  .fake-videoCall-top-outer{
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 425px;
    top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
   
  .host-image-outer{
    width: 164px;
    height: 164px;
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   
   
  .host-image-outer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
   
  .host-details-outer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
  }
   
  .host-details-outer h1{
    font-size: 22px;
    color: var(--white);
    font-family: 'SF Pro Display, Bold';
  }
   
  .host-details-outer p{
    font-size: 18px;
    color: var(--white);
    font-family: 'SF Pro Display, Regular';
  }
   
   
  .fake-videoCall-bottom-outer{
    position: absolute;
    z-index: 999;
    width: 100%;
    bottom: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
  }
   
  .call-btn-outer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
   
  .call-btn-outer p{
    font-size: 16px;
    color: var(--white);
    font-family: 'SF Pro Display, Medium';
  }
   
  .call-cut-inner{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-image: url('../../../assets/call_cut_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
   
  .call-pick-inner{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-image: url('../../../assets/call_pick_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }


.fake-call-outer{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.fake-call-outer video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.fake-call-user-view{
    position: absolute;
    bottom: 175px;
    right: 15px;
    width: 125px;
    height: 150px;
    z-index: 99;
    overflow: hidden;
    border-radius: 18px;
}

.fake-call-user-view video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.fake-call-loader-overlay{
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
    z-index: 2;
}


.fake-caller-details-outer{
    position: absolute;
    left: 15px;
    top: 23px;
    z-index: 9;
    display: flex;
    align-items: center;
    height: 45px;
    width: 150px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 23px;
    padding: 5px;
}

  
  .fake-caller-profile-outer {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fake-caller-profile-outer img {
    width: 100%;
    object-fit: cover;
  }
  
  .fake-caller-host-name-outer {
    margin-left: 5px;
    margin-right: 5px;
    width: calc(100% - 45px);
  }
  
  .fake-caller-host-name-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .fake-caller-host-inner-text {
    font-size: 14px;
    font-family: "SF Pro Display , Regular";
    font-style: normal;
    color: var(--white);
    margin: 0;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .fake-caller-inner-call-duration {
    font-size: 12px;
    font-family: "SF Pro Display , Regular";
    font-style: normal;
    color: var(--white);
    margin: 0;
  }


.user-options-outer{
    width: 100%;
    position: absolute;
    bottom: 25px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.video-icon-outer{
    width: 40px;
    height: 40px;
    overflow: hidden;
}

.video-icon-outer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.call-cut-icon-outer{
    width: 40px;
    height: 40px;
    overflow: hidden;
}

.call-cut-icon-outer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.fake-call-user-view video{
  border-radius: 5px;
}