@font-face {
  font-family: 'SF Pro Display, Heavy Italic';
  src: url('./SFProDisplay-HeavyItalic.woff2') format('woff2'),
      url('./SFProDisplay-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./SFProDisplay-BlackItalic.woff2') format('woff2'),
      url('./SFProDisplay-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display , Bold';
  src: url('./SFProDisplay-Bold.woff2') format('woff2'),
      url('./SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SF Pro Display, Bold';
  src: url('./SFProDisplay-Bold.woff2') format('woff2'),
      url('./SFProDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./SFProDisplay-LightItalic.woff2') format('woff2'),
      url('./SFProDisplay-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display, Medium';
  src: url('./SFProDisplay-Medium.woff2') format('woff2'),
      url('./SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display, Regular';
  src: url('./SFProDisplay-Regular.woff2') format('woff2'),
      url('./SFProDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./SFProDisplay-SemiboldItalic.woff2') format('woff2'),
      url('./SFProDisplay-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./SFProDisplay-ThinItalic.woff2') format('woff2'),
      url('./SFProDisplay-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./SFProDisplay-UltralightItalic.woff2') format('woff2'),
      url('./SFProDisplay-UltralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

