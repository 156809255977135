  @import '../src/assets/fonts/fonts.css';
  :root {
    --dark-gray: #3D3D3D;
    --light-gray: #E1E1E1;
    --black: #1C1C1C;
    --soft-gray: #DEDEDE;
    --secondary-color: #8E27A7;
    --primary-color: #341058;
    --white: #FFFFFF;
    --black:#000000;
    --text-theme:#FE339F;
    --danger-color:#FF4444;
    --bg-color:#8C0DAE;
    --user-chat-color: #FFB916;
    --text-color: #2d2d2d;
    --light-text: #707070;
    --notification-color :#FE48EB;
    --header-footer-bg :#F6F6F6;
    --send-msg: #B83EFA;
    --otp-text:#E955FF;
    --color-gray-lighter: #EAEAEA;
  }

  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  
  ul{list-style-type:none;padding:0!important;margin:0!important}
  h1,
  h2,
  h3, h4, h5, h6{margin: 0;}

  p{margin:0 !important}
  img{
    display: block;
    line-height: 0
  }


*{
  user-select: none;
}

.animation-streming{
  width: 60px;
  height: 60px;
}
