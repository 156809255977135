.bottom-nav {
    width: 100%;
    padding: 10px 40px;
    height: 50px;
    background: var(--white) 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000040;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    position:fixed;
    bottom: 0;
}

.bottom-icons-outer{
    /* width: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-icons{
    width: 30px;
    height: 30px;
}

.btm-icon-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mr30{
    margin-right: 30px;
}