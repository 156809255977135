@media (min-width: 768px) {
    .fakecall-popup-overlay {
        border: 2px solid var(--primary-color); 
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 430px; 
        margin:0 auto
    }
}

.fakecall-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    z-index: 1000;
}

.fakecall-popup-box {
    width: 100%;
    background: #fff;
    padding: 25px 15px;
    height: auto;
    border-radius: 22px 22px 0px 0px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fakecall-popup-text-outer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
}

.fakecall-button-group{
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.fakecall-confirm-button {
    width: 100%;
    max-width: 350px;
    height: 55px;
    background: linear-gradient(90deg, #E755FF, #A759FD);
    border-radius: 25px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fakecall-popup-text-top {
    font-family: 'SF Pro Display , Bold';
    font-size: 26px;
    text-align: center;
    color: #4E4E4E;
}
.fakecall-popup-text {
    font-family: 'SF Pro Display , Regular ';
    font-size: 20px;
    text-align: center;
    color: #4E4E4E;
}

.fakecall-confirm-button p{
    color: var(--white);
    font-size: 20px;
    font-family: 'SF Pro Display, Regular';
}


.fakecall-popup-amt-details{
    width: 100%;
    max-width: 254px;
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-image: url('../../assets/popup_bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px 15px;
}

.diamond-outer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.diamond-icon-outer{
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.diamond-icon-outer img{
    height: 100%;
    object-fit: cover;
}



.diamond-outer p{
    font-size: 26px;
    color: #EA3A21;
    font-family: 'SF Pro Display, Bold';
}

.amount-outer p{
    color: var(--white);
    font-size: 26px;
    font-family: 'SF Pro Display, Bold';
}
