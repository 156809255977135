.toast-outer{
    position: absolute;
    top: 10px;
    right: 0;
    background: linear-gradient(to right, #6409A8, #DC35F2);
    padding: 10px 15px;
    border-radius: 13px;
    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.6);
    z-index: 999;
    left: 0;
    width: 95%;
    margin: 0 auto;
}

.toast-inner{
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
}

.toast-image-outer{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border: 2px solid var(--white);
}

.toast-image-outer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%
}

.toast-right-sec-outer {
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-left: 5px;
}

.host-name-outer{
    display: flex;
    align-items: start;
    margin: 8px 0px;
    gap: 8px;
    position: relative;
}

.time-indicator {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 4px;
  }

  .dot {
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
  }

  .time {
    color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
  }

.status-indicator {
    flex-shrink: 0;
    position: absolute;
}

.status-indicator p {
    font-family: "SF Pro Display, Regular";
    color: var(--white);
    font-size: 8px;
    text-transform: capitalize;
}

.host-name-outer p{
    font-size: 16px;
    color:var(--white);
    max-width: 135px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "SF Pro Display, Bold";
    text-transform: capitalize;
}

.text-message-outer p {
    font-size: 14px;
    color:var(--white);
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "SF Pro Display, Regular";
    text-transform: capitalize;
}

.reply-button{
    padding: 5px 15px;
    border-radius: 12px;
    width: 66px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;

}
.reply-button p{
    font-size: 12px;
    color: #6409A8;
    font-family: 'SF Pro Display, Regular';
}
